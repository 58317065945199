<template>
  <div class="form-section">
    <h2
      v-if="title"
      class="form-section-title"
    >
      {{ title }}
    </h2>
    <p
      v-if="description"
      class="form-section-description"
      v-html="description"
    />
    <div class="form-section-field-container">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'FormSection',
  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },
    description: {
      type: String,
      required: false,
      default: '',
    },
  },
};
</script>

<style lang='scss' scoped>
.form-section {
  text-align: left;
  margin-bottom: 25px;
  clear: both;
  .form-section-title {
    margin-bottom: 10px;
  }
  .form-section-description {
    // margin-bottom: 20px;
    // margin-left: 5px;
    padding: 0px 0px 15px 0px;
    // border-left: 4px lighten(#21BECE, 20%) solid;
    // border-bottom-left-radius: 5px;
    // background-color: #EEE;
    // border-top-right-radius: 5px;
    // border-bottom-right-radius: 5px;
  }
}
</style>
