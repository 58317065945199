export default {
  methods: {
    validateState(ref) {
      if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        // console.log((!this.veeErrors.has(ref)) ? 'state is good' : 'rawr');
        return !this.veeErrors.has(ref);
      }
      return null;
    },
  },
};
