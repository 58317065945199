<template>
  <div id="password-reset-confirmation">
    <div v-if="allowReset">
      <h1 aria-label="Password Reset">
        {{ title }}
      </h1>
      <b-form
        v-if="!passwordUpdated"
        class="form-container"
        @submit.prevent
      >
        <FormProgressBar :progress="progress()" />
        <!-- ERRORS -->
        <FormErrors />
        <div
          class="form"
        >
          <!--  EMAIL -->
          <FormSection
            description="Please enter your email address again and new password."
          >
            <FormGroup
              for="email"
              label="Email Address"
              class="w-100"
            >
              <FormInput
                v-validate="{ required: true, email: true }"
                :state="validateState('Email')"
                name="Email"
                bind-id="email"
                get="email"
                set="UPDATE_EMAIL"
                invalid-feedback="Please enter a valid Email."
                invalid-feedback-id="email-invalid-feedback"
                autocomplete="username"
                @keyup.enter.native="updatePassword"
              />
            </FormGroup>
          </FormSection>
          <!-- PASSWORD -->
          <FormSection>
            <FormGroup
              for="password"
              label="Password"
              class="w-100 tip"
              tip="At least 8 characters"
            >
              <FormInput
                v-validate="{ required: true, password: true }"
                :state="validateState('password')"
                name="Password"
                bind-id="password"
                get="password"
                set="UPDATE_PASSWORD"
                type="password"
                invalid-feedback-id="invalid-password"
                autocomplete="new-password"
                @keyup.enter.native="updatePassword"
              />
            </FormGroup>
          </FormSection>
        </div>
        <b-button
          :disabled="submitButtonDisabled"
          class="signup-buttons w-50-100"
          size="lg"
          variant="primary"
          @click="updatePassword()"
        >
          Submit
        </b-button>
      </b-form>
      <div v-else>
        <p class="pb-4">
          Your password has been successfully updated. An email will be sent shortly for your
          records. Please use your new password on your next login.
        </p>
        <b-button
          class="w-25"
          size="lg"
          variant="primary"
          @click="done"
        >
          Done
        </b-button>
      </div>
    </div>
    <div v-if="allowReset === null">
      <h1>Loading</h1>
    </div>
    <div v-if="allowReset === false">
      <h1>Invalid Password Reset Link</h1>
      <p class="padding-password-reset">
        The password reset link has expired. Please reset your Aira password again
        to obtain a new password reset link. If you believe this to be a mistake, please call us at
        <a href="tel:1-800-835-1934"> 1-800-835-1934</a>, or drop a note with your phone number at <a
          target="_top"
          href="mailto:your@email.address?subject=Comments about the color blue"
        > support@aira.io</a>, and we will get back to
        you.
      </p>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import ValidationMixin from '../../mixin/ValidationMixin';
import FormErrors from '../Form/FormErrors.vue';
import FormSection from '../Form/FormSection.vue';
import FormGroup from '../Form/FormGroup.vue';
import FormProgressBar from '../Form/FormProgressBar.vue';
import FormInput from '../Form/FormInput.vue';
import AiraError from '../../utils/AiraError';

export default {
  name: 'PasswordReset',
  components: {
    FormErrors,
    FormSection,
    FormGroup,
    FormProgressBar,
    FormInput,
  },
  mixins: [
    ValidationMixin,
  ],
  data() {
    return {
      pageName: 'passwordReset',
      submitButtonDisabled: false,
      passwordUpdated: false,
      allowReset: null,
    };
  },
  computed: {
    ...mapGetters([
      'email',
      'password',
      'errors',
    ]),
    title() {
      if (this.passwordUpdated) return 'Password successfully updated';
      return 'Password Reset';
    },
  },
  created() {
    this.checkCode();
  },
  methods: {
    async checkCode() {
      try {
        const codeVerify = await this.$store.dispatch('passwordResetVerifyCode', { code: this.$route.query.code });
        this.allowReset = true;
        this.code = codeVerify.requestKey;
        this.userId = codeVerify.userId;
      } catch (e) {
        console.error('checkCode resp', e);
        this.allowReset = false;
      }
    },
    progress() {
      return { full: true };
    },
    validateFields() {
      const fieldErrors = [];
      if (!this.email) fieldErrors.push('Email is missing');
      if (this.password.length < 8) fieldErrors.push('Password must be at least 8 characters');
      return fieldErrors;
    },
    async updatePassword() {
      this.submitButtonDisabled = true;

      // validate fields
      this.$store.commit('CLEAR_ERRORS');
      const errors = this.validateFields();
      if (errors.length) {
        this.$store.commit('PUSH_ERRORS', errors);
        this.submitButtonDisabled = false;
        return;
      }

      try {
        await this.$store.dispatch('updatePassword', {
          email: this.email,
          password: this.password,
          code: this.$route.query.code,
        });
        this.passwordUpdated = true;
      } catch (e) {
        console.error('updatePassword error', e);
        const airaError = new AiraError(e);
        this.$store.commit('PUSH_ERRORS', airaError.errorMessage);
        return;
      } finally {
        this.submitButtonDisabled = false;
      }
    },
    done() {
      window.location.href = 'https://aira.io/';
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../styles/variables/_colors.scss';
@import '../../styles/variables/_sizes.scss';
@import '../../styles/theme/_default.scss';

#password-reset-confirmation {
  margin-top: 50px;
  .form {
    padding: 20px 20px 0px 20px;
  }
}
.padding-password-reset {
  padding: 25px 300px 50px 300px;
}
</style>
